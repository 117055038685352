module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eat Bitter","short_name":"Eat Bitter","start_url":"/","background_color":"#000000","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/img/eat-bitter-calligraphy-black.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0060f9a6c34a2c47f7efd43b2c8e9e8f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156360115-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
